import React from 'react'
import {
  ActionButton,
  AgreementContentContainer,
  DisabledStyleButClickableActionButton,
  HelpContainer,
  InstructorAgreementCardContent,
  StyledAgreeLabelContainer,
  StyledCard,
  StyledCheckbox,
  WarningContainer,
} from '../Agreement.styles'
import { FormControlLabel, Typography } from '@material-ui/core'
import FullScreenBackdropDialog from '../../layout/FullScreenBackdropDialog'
import { InstructorAgreementProps } from './InstructorAgreement.types'
import { RichTextRendererDangerously } from 'src/components/dataDisplay/RichTextRendererDangerously'
import Divider from 'src/components/Divider'
import {
  Illustration,
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'
import { ElementCaption } from 'src/components/Caption'
import useMediaQueries from 'src/hooks/useMediaQueries'
import { useAgreementTranslation } from '../Agreement.translation'
import ModalLink from 'src/components/navigation/ModalLink'
import { ModalPaths } from 'src/routes'
import theme from 'src/styles/themeMUIv5'
import Box from '@mui/material/Box'
import useProfiles from 'src/hooks/useProfiles'
import { UserProfileType } from 'src/generated/graphql'

export const InstructorAgreement: React.FC<InstructorAgreementProps> = ({
  documentContent,
  loading,
  isAccepted,
  showWarning,
  signAgreement,
  setWarningShowing,
  onAccept,
}) => {
  const { isExtraSmallScreen: isMobile } = useMediaQueries()

  const {
    HEADER,
    AGREE_TO_TERMS_TEXT,
    ACCEPT_AND_CONTINUE,
    PROCEED_PROMPT,
    NEED_HELP,
    CONTACT_COMPANY_NAME,
    CONTACT_EMAIL,
    CONTACT_PHONE,
    CONTACT_ADDRESS,
    PHONE,
    EMAIL,
    SWITCH_PROFILE,
  } = useAgreementTranslation()

  const { data: profiles } = useProfiles()
  const hasClubProfile = profiles.availableProfileTypes?.includes(
    UserProfileType.Club
  )

  return (
    <FullScreenBackdropDialog>
      <StyledCard raised>
        <InstructorAgreementCardContent>
          <Typography variant="h6">{HEADER}</Typography>
          <Divider mt={isMobile ? 3 : 4} />
          <AgreementContentContainer>
            <RichTextRendererDangerously htmlString={documentContent} />
          </AgreementContentContainer>
          {showWarning ? (
            <WarningContainer>
              <ElementCaption
                element={
                  <Typography component="span" variant="body1" color="error">
                    {PROCEED_PROMPT}
                  </Typography>
                }
                icon={UtilityIcon.RedWarning}
                isOneLine
              />
            </WarningContainer>
          ) : null}
          <StyledAgreeLabelContainer>
            <FormControlLabel
              label={<span>{AGREE_TO_TERMS_TEXT}</span>}
              control={
                <StyledCheckbox
                  checked={isAccepted}
                  onChange={onAccept}
                  icon={
                    <Illustration type={UtilityIcon.EmptyCircle} width={24} />
                  }
                  checkedIcon={
                    <Illustration type={UtilityIcon.GoldTick} width={24} />
                  }
                />
              }
            />
          </StyledAgreeLabelContainer>
          <Box
            mt={3}
            mb={2}
            display="flex"
            flexDirection="column"
            gap={theme.spacing(5)}
          >
            <HelpContainer>
              <Typography variant="body2">{NEED_HELP}</Typography>
              <Typography variant="body2" color="textSecondary">
                {CONTACT_COMPANY_NAME}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {CONTACT_ADDRESS}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <strong>{PHONE}: </strong>
                {CONTACT_PHONE}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <strong>{EMAIL}: </strong>
                {CONTACT_EMAIL}
              </Typography>
            </HelpContainer>

            <Box
              width={'100%'}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="end"
              gap={theme.spacing(2)}
            >
              {hasClubProfile && (
                <ModalLink fullWidth={isMobile} to={ModalPaths.SwitchProfile}>
                  <ActionButton
                    variant="contained"
                    color="secondary"
                    loading={loading}
                    fullWidth={isMobile}
                  >
                    {SWITCH_PROFILE}
                  </ActionButton>
                </ModalLink>
              )}

              <DisabledStyleButClickableActionButton
                variant="contained"
                color="primary"
                onClick={isAccepted ? signAgreement : setWarningShowing}
                loading={loading}
                $isDisabledStyle={!isAccepted}
              >
                {ACCEPT_AND_CONTINUE}
              </DisabledStyleButClickableActionButton>
            </Box>
          </Box>
        </InstructorAgreementCardContent>
      </StyledCard>
    </FullScreenBackdropDialog>
  )
}
